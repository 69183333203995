<template>
  <header class="mdc-top-app-bar">
    <div class="mdc-top-app-bar__row">
      <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
        <button class="material-icons md-48">
          <router-link to="/" tag="button" class="title">
            <img src="./assets/finalchoice.png"/>
          </router-link>
        </button>
        <router-link to="/" tag="button" class="title">FinalChoice</router-link>
        <!-- <a class="mdc-top-app-bar__title" href="">Finalchoice</a> -->
      </section>
      <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end" role="toolbar">
        <!-- <button class="material-icons mdc-top-app-bar__action-item mdc-icon-button"
                aria-label="Favorite" v-bind:class="{'color':isActive}">favorite</button> -->
        <button  class="mdc-top-app-bar__action-item mdc-icon-button user-icon" @click="isActive=!isActive"
        v-bind:class="{'hamburger_btn':isActive}" v-if="isLogined">
          <div class="icon" :style="{ background: colorCode() }">{{ username.slice(0, 1) }}</div>
        </button>
        <div v-else>
          <router-link to="/login" tag="button" class="title" >Login</router-link>
          <button  class="mdc-top-app-bar__action-item mdc-icon-button" style="padding-top: 10px;" @click="isActive=!isActive"
          v-bind:class="{'hamburger_btn':isActive}">
            <i class="material-icons pink" v-bind:class="{'color':isActive}">menu</i>
          </button>
        </div>

        <!-- ハンバーガーメニュー -->
        <transition name="hamburger__menu">
          <div>
            <!-- ハンバーガーメニュー背景用 -->
            <div class="hamburger__menu__bg" @click="isActive=false" v-show="isActive"></div>

            <div class="hamburger__menu" v-show="isActive">
              <div class="hm__user__info">
                <label class="hm__user__info_name">{{ username }}</label><br>
                <label class="hm__user__info_email">{{ email }}</label>
              </div>
              <div class="hm__contents">
                <ul>
                  <li><router-link to="/" @click="isActive=!isActive">Top</router-link></li>
                  <li><router-link to="/news" @click="isActive=!isActive">News</router-link></li>
                  <li><router-link to="/contact" @click="isActive=!isActive">Contact</router-link></li>
                  <li v-if="isLogined"><router-link to="/setting" @click="isActive=!isActive">Setting</router-link></li>
                  <li><router-link to="/login" @click="signOut(); isActive=!isActive" v-show="isLogined">Logout</router-link></li>
                </ul>
              </div>

              <div class="hm__footer">
                Powered by <a href="http://webservice.recruit.co.jp/">ホットペッパー Webサービス</a>
              </div>
            </div>
          </div>
        </transition>

      </section>
    </div>
  </header>
  <main class="mdc-top-app-bar--fixed-adjust" v-bind:class="{'scroll-prevent':isActive}">
    <router-view/>
  </main>
</template>

<script>
import {MDCTopAppBar} from '@material/top-app-bar';
import { Auth } from "aws-amplify";
import { onAuthUIStateChange } from '@aws-amplify/ui-components'

export default {
  name: 'App',

  methods: {
    checkLogIn: function () {
      Auth.currentAuthenticatedUser().then(user => {
        console.log(user);
        // ユーザー名（ユーザーID）
        console.log('UserName' + user.username);
        this.username = user.username;
        // メールアドレス
        console.log('Email' + user.attributes.email);
        this.email = user.attributes.email;

        this.isLogined = true;
      }).catch(err => console.log(err));
    },
    signOut:async function() {
      try {
        await Auth.signOut();
        
        this.username = "";
        this.email = "";
        this.isLogined = false;
      } catch (error) {
        console.log('error signing out: ', error);
      }
    },
    colorCode() {
      return this.strToRGBCode(this.username)
    },
    // ASCII文字コードをRGB値に変換
    charToRGB(c) {
      var h = 22.5 * (c % 16), s = 0.1*(c/16) + 0.3, v = 1.0;
      var h_i = Math.floor(h/60.0);
      var f = (h/60.0) - h_i;
      var p = v*(1-s), q = v*(1-f*s),  t = v*(1-(1-f)*s);
      var rgb = [[v,t,p],[q,v,p],[p,v,t],[p,q,v],[t,q,v],[v,p,q]];
      var r = ~~(255*(rgb[h_i][0])), g = ~~(255*(rgb[h_i][1])), b = ~~(255*(rgb[h_i][2]));

      return [r,g,b];
    },
    // ASCII文字列をRGB値に変換
    strToRGB(str) {
      var i,c,a;
      var r = 0, g = 0, b = 0;
      for (i = 0; i < str.length; i++) {
        c = str.charCodeAt(i);
        a = this.charToRGB(c);
        r += a[0]; g += a[1]; b += a[2];
      }

      r = ~~(r / str.length);
      g = ~~(g / str.length);
      b = ~~(b / str.length);

      return [r,g,b];
    },
    rgbToRGBCode (rgb) {
      return '#' + rgb.map(this.intToHex).join('');
    },
    intToHex (i) {
      var str = i.toString(16);
      return i < 16 ? "0" + str : str
    },
    strToRGBCode(text) {
      return this.rgbToRGBCode(this.strToRGB(text));
    }
  },

  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState) => {
      // 処理
      if (authState === 'signedin') {
        this.checkLogIn();
      }

    })
  },

  mounted() {
    // Instantiation
    const topAppBarElement = document.querySelector('.mdc-top-app-bar');
    const topAppBar = new MDCTopAppBar(topAppBarElement); // eslint-disable-line no-unused-vars
    this.checkLogIn();
  },

  data() {
    return {
      username: "",
      email: "",
      isActive: false,
      isLogined: false,
    }
  }
}
</script>

<style lang="scss">
@use "@material/theme" with (
  $primary: #FFE27A,
  // $secondary: #feeae6,
  // $on-primary: #442b2d,
  // $on-secondary: #442b2d,
);
@use "@material/icon-button/mdc-icon-button";
@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/typography/mdc-typography";

// @include button.core-styles;

// $top-app-bar-fill-color: #001A43;

#app {
  font-family: "Noto Sans JP";
}

.title {
  color: black;
  text-decoration: none;
  font-size:  20px;
  font-weight: bold;
}

header.mdc-top-app-bar {
  top: 0;
  left: 0;
  right: 0;
  // @include top-app-bar.fill-color-accessible(#42b983);
}

body {
  background-color: rgba(255,226,122, 0.35);
}

.hamburger__menu {
  background-color: #FFE27A;
  z-index: 30;
  padding: 2rem 1rem;
  position: fixed;
  width: 170px;
  height: 80rem;
  top: 0;
  right: 0;

  .hm__user__info {
    margin-top: 20px;
    margin-left: 15px;

    .hm__user__info_name {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .hm__contents {
    ul{
    padding: 0;
    padding-top: 40px;
    }

    li {
      padding: 35px 0;
    list-style: none;
    line-height: 0.5;
    }

    a {
    color: rgb(66, 66, 66);
    text-decoration: none;
    font-size: 25px;
    margin: 0 4vw;
    padding-bottom: 8px;
    }
  }

  .hm__footer {
    position: fixed;
    bottom: 0;
  }
}

.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  }

.hamburger__menu__bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 29;
  background: #333;
  opacity: 0.6;
  transition: all 0.6s;
  cursor: pointer;
}

.color {
  color: black;
}

.icon {
  color: white;
  background: #808080;
  border-radius: 50%;
  justify-content: center;
  height: 32px;
  width: 32px;
  display: flex;
}

.user-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  padding-left: 8px;
}

.hamburger_btn{
  z-index: 50;
}

/* アイコンサイズ */
 .md-48 {
  border: none;  /* 枠線を消す */
  outline: none; /* クリックしたときに表示される枠線を消す */
  background: transparent; /* 背景の灰色を消す */
  padding: 0;
}

.md-48 img {
    width: 48px;
    height: 48px;
}

</style>
