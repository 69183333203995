/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "user-staging",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "finalchoiceapi",
            "endpoint": "https://43u0gbermh.execute-api.ap-northeast-1.amazonaws.com/staging",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:d7366a08-d677-4ece-8d27-c7aa85156a20",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_ZqpzuPZgU",
    "aws_user_pools_web_client_id": "52jae6r8ishfjsj36j7oa3pjv2",
    "oauth": {}
};


export default awsmobile;
