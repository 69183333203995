<template>
  <div class="home">
    <div class="tap-message">ボタンをタップして居酒屋をチョイス！</div>

    <button class="clear-decoration" @click="clickGotoStoreDetails"><img src="../assets/button.png"/></button>

    <div class="info-message">
    お店選びに困ったことはありませんか？<br>
    周囲の飲食店からランダムにお店をチョイス！<br>
    意外な隠れ家店が見つかるかも！？<br>
    ※位置情報の取得を許可して頂く必要があります。<br>
    </div>
    <div class="addicon">
        <router-link to="/addicon">ホーム画面へのアイコン追加方法はこちら</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
  },
  methods: {
    // TODO ボタンが押下された場合にstoreDetails画面に遷移する
    clickGotoStoreDetails() {
      this.$router.push({path: '/store-details'});
    },
  }
}
</script>

<style lang="scss" scoped>

.home {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.tap-message {
  padding: 2rem;
  margin: 0 auto;
}

.clear-decoration {
    border: none;  /* 枠線を消す */
    outline: none; /* クリックしたときに表示される枠線を消す */
    background: transparent; /* 背景の灰色を消す */
}
.info-message {
  padding: 2rem;
  margin: 0 auto;
  line-height: 1.8em;
}
</style>
